export const BRAND_CONFIG = {
  ORG_NAME: 'HFX e-Scooters',
  ORG_LIGHT_LOGO:
    'https://images.squarespace-cdn.com/content/v1/611d17148d67a721a526e30d/e77122f0-22f3-45b6-9880-cac9e6734cab/Untitled+design-6.png?format=120w',
  ORG_DARK_LOGO:
    'https://images.squarespace-cdn.com/content/v1/611d17148d67a721a526e30d/e77122f0-22f3-45b6-9880-cac9e6734cab/Untitled+design-6.png?format=120w',
  LOGO_SIZE: {
    LOGIN_PAGE: {
      WIDTH: 120,
    },
    TOPBAR: {
      WIDTH: 120,
      HEIGHT: 80,
    },
  },
  ORG_EMAIL: 'test@otoride.co',
  COLOR: {
    PRIMARY_COLOR: '#ed1b24',
    BUTTON_TEXT_PRIMARY_COLOR: '#ffffff',
    BUTTON_TEXT_SECONDARY_COLOR: '#ed1b24',
    HOMEPAGE_BRAND_TITLE_COLOR: '#ed1b24',
    HOMEPAGE_SVG_LOGO_BG_COLOR: '#ed1b24',
  },
}
